<template>
  <div>
    <v-container fluid class="py-6">
      <v-row>
        <v-col class="mx-auto">
          <v-card class="card-shadow border-radius-xl">
            <div class="px-4 pt-4">
              <div class="ms-4">
                <h5 class="text-h5 text-typo font-weight-bold mb-2">
                  訂單總結
                </h5>
              </div>
              <div class="d-flex align-center">
                <slot name="header"></slot>
              </div>
            </div>
            <div class="px-4 pb-4">
              <hr class="horizontal dark mt-0 mb-6" />
              <v-row>
                <v-col lg="8" md="8">
                  <slot name="summary"> </slot>
                </v-col>
                <v-col lg="4" md="4">
                  <h6 class="text-h6 text-typo font-weight-bold mb-3">
                    Payment 支付方式
                  </h6>
                  <div class="pa-5 border border-radius-lg d-flex align-center">
                    <v-radio-group v-model="payment" mandatory row>
                      <v-radio
                        label="信用卡"
                        value="0"
                        key="credit"
                        v-on:change="onPaymentChange(0)"
                      >
                        <v-icon>mdi-store-clock-outline</v-icon></v-radio
                      >
                      <v-radio
                        v-if="allow_point"
                        label="點數"
                        value="1"
                        key="point"
                        v-on:change="onPaymentChange(1)"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <h6 class="text-h6 mb-3 mt-3 text-red">
                    測試卡號: 4000-2211-1111-1111 其餘任意填寫
                  </h6>
                  <h6 class="text-h5 text-typo font-weight-bold mb-3 mt-3">
                    Order Summary
                  </h6>
                  <div class="d-flex">
                    <span class="mb-2 text-body text-sm"> Product Price: </span>
                    <span class="text-dark font-weight-bold ms-auto">{{
                      total
                    }}</span>
                  </div>
                  <div class="d-flex">
                    <span class="mb-2 text-body text-sm"> Taxes(5%): </span>
                    <span class="text-dark font-weight-bold ms-auto">{{
                      tax
                    }}</span>
                  </div>
                  <div class="d-flex mt-6">
                    <span class="mb-2 text-body text-lg"> Total: </span>
                    <span
                      class="text-dark text-lg ms-2 font-weight-bold ms-auto"
                      >{{ tax_include }}</span
                    >
                  </div>
                </v-col>
                <v-col lg="3" class="ms-auto"> </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    buy_items: {
      type: [Array],
      default: () => [],
    },
    start_date: {
      type: String,
      default: null,
    },
    allow_point: {
      type: Boolean,
      default: true,
    },
  },
  data(vm) {
    return {
      payment: 0,
      buy_options: {},
      headers: [
        {
          text: vm.$i18n.t("district_name"),
          value: "district_name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("region_name"),
          value: "region_name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("key_num"),
          value: "key_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("owner_num"),
          value: "owner_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("Price"),
          value: "price",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    total() {
      // return this.editedIndex === -1 ? "New Item" : "Edit Item";
      let total = 0;
      this.buy_items.forEach((item) => {
        total += parseInt(item.price);
      });
      return total;
    },
    tax() {
      return Math.round(0.05 * this.total);
    },
    tax_include() {
      return this.total + this.tax;
    },
  },
  methods: {
    ...mapGetters(["getCapability"]),
    reset() {
      console.log("reset summary");
    },
    onPaymentChange(value) {
      this.$emit("paymentChange", value);
    },
  },
};
</script>
