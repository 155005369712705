const fake_search_result = [
  {
    id: "8094",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0001",
    region_name: "桃園段武陵小段",
    key_num: 1936,
    owner_num: 9437,
    type: 0,
    price: 3774,
    stock_price: 943,
  },
  {
    id: "8095",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0002",
    region_name: "桃園段中南小段",
    key_num: 927,
    owner_num: 3167,
    type: 0,
    price: 1266,
    stock_price: 316,
  },
  {
    id: "8096",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0003",
    region_name: "桃園段長美小段",
    key_num: 1165,
    owner_num: 4445,
    type: 0,
    price: 1778,
    stock_price: 444,
  },
  {
    id: "8097",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0004",
    region_name: "桃園段公館頭小段",
    key_num: 265,
    owner_num: 1708,
    type: 0,
    price: 683,
    stock_price: 170,
  },
  {
    id: "8099",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0006",
    region_name: "小檜溪段",
    key_num: 2805,
    owner_num: 10278,
    type: 0,
    price: 4111,
    stock_price: 1027,
  },
  {
    id: "8101",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0008",
    region_name: "中路段",
    key_num: 7164,
    owner_num: 30693,
    type: 0,
    price: 12277,
    stock_price: 3069,
  },
  {
    id: "8102",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0009",
    region_name: "大樹林段",
    key_num: 4819,
    owner_num: 17742,
    type: 0,
    price: 7096,
    stock_price: 1774,
  },
  {
    id: "8103",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0010",
    region_name: "埔子段埔子小段",
    key_num: 7426,
    owner_num: 27765,
    type: 0,
    price: 11106,
    stock_price: 2776,
  },
  {
    id: "8104",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0011",
    region_name: "埔子段北門埔子小段",
    key_num: 2342,
    owner_num: 10005,
    type: 0,
    price: 4002,
    stock_price: 1000,
  },
  {
    id: "8108",
    city: "City object (8)",
    city_name: "桃園市",
    district: "District object (165)",
    district_name: "桃園區",
    region: "0015",
    region_name: "和平段",
    key_num: 1405,
    owner_num: 8515,
    type: 0,
    price: 3406,
    stock_price: 851,
  },
];

export { fake_search_result };
