<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-6 py-6">
      <div class="d-flex align-center">
        <div>
          <h4 class="text-h4 text-typo font-weight-bold mb-2">
            訂單完成 {{ getOrderTitle() }}
          </h4>
          <p class="text-h5 text-body">
            開始 <b>{{ order_info.start_date }}</b> 預計結束
            <b>{{ order_info.end_date }}</b>
          </p>
          <p class="text-h5 text-body">
            訂單: <b>{{ order_info.order_id }}</b>
          </p>
        </div>

        <v-btn
          v-if="allow_back"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-secondary
            py-2
            px-6
            me-2
            ms-auto
          "
          color="#5e72e4"
          small
          @click="$router.go(-1)"
          >Back</v-btn
        >
        <v-btn
          v-else
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-secondary
            py-2
            px-6
            me-2
            ms-auto
          "
          color="#5e72e4"
          small
          >Invoice</v-btn
        >
      </div>
    </div>
    <div class="px-6 pb-4">
      <hr class="horizontal dark mt-0 mb-6" />
      <v-row v-if="false">
        <v-col md="6">
          <div class="d-flex">
            <v-avatar
              class="me-4 border-radius-lg"
              alt="product-image"
              width="110px"
              height="110px"
            >
              <v-img
                src="https://images.unsplash.com/photo-1511499767150-a48a237f0083?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
              >
              </v-img>
            </v-avatar>
            <div>
              <h6
                class="
                  text-h6 text-typo text-lg
                  font-weight-bold
                  mb-0
                  mt-2
                  mb-1
                "
              >
                Gold Glasses
              </h6>
              <p class="text-sm text-body mb-3">
                Order was delivered 2 days ago.
              </p>
              <v-btn
                elevation="0"
                small
                :ripple="false"
                height="21"
                class="
                  border-radius-sm
                  bg-gradient-success
                  text-xxs
                  font-weight-bolder
                  px-3
                  py-2
                  badge-font-size
                  ms-auto
                  text-white
                "
                color="#cdf59b"
                >Delivered</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col md="6" class="text-end my-auto">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-info
              py-2
              px-6
              me-2
              ms-auto
            "
            color="#5e72e4"
            small
            >Contact us</v-btn
          >
          <p class="text-sm text-body mt-2 mb-0">
            Do you like the product? Leave us a review
            <a href="javascript:;" class="text-dark text-decoration-none"
              >here</a
            >.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="8" md="8">
          <!-- <h6 class="text-h6 text-typo font-weight-bold mb-4">
                    Order Detail
                  </h6> -->
          <v-data-table
            :headers="headers"
            :options.sync="buy_options"
            :items="buy_items"
            hide-default-footer
            disable-pagination
          ></v-data-table>
        </v-col>
        <v-col lg="4" md="4">
          <h6 class="text-h6 text-typo font-weight-bold mb-3">
            Payment 支付方式
          </h6>
          <div
            class="pa-5 border border-radius-lg d-flex align-center"
            v-if="order_info.pay == 0"
          >
            <v-avatar size="36" rounded class="me-4">
              <v-img
                src="@/assets/img/logos/visa.png"
                alt="logo"
                max-width="80"
                contain
              ></v-img>
            </v-avatar>
            <h6 class="mb-0 text-h6 text-typo">信用卡</h6>
          </div>
          <div class="pa-5 border border-radius-lg d-flex align-center" v-else>
            <v-avatar size="36" rounded class="me-4">
              <v-img
                src="@/assets/img/sketch.jpg"
                alt="logo"
                max-width="80"
                contain
              ></v-img>
            </v-avatar>
            <h6 class="mb-0 text-h6 text-typo">點數</h6>
          </div>
          <h6 class="text-h6 text-typo font-weight-bold mb-3 mt-3">
            Order Summary
          </h6>
          <div class="d-flex">
            <span class="mb-2 text-body text-sm"> Product Price: </span>
            <span class="text-dark font-weight-bold ms-auto">{{ total }}</span>
          </div>
          <!-- <div class="d-flex">
                    <span class="mb-2 text-body text-sm"> Tax: </span>
                    <span class="text-dark font-weight-bold ms-auto">0</span>
                  </div> -->
          <div class="d-flex">
            <span class="mb-2 text-body text-sm"> Taxes(5%): </span>
            <span class="text-dark font-weight-bold ms-auto">{{ tax }}</span>
          </div>
          <div class="d-flex mt-6">
            <span class="mb-2 text-body text-lg"> Total: </span>
            <span class="text-dark text-lg ms-2 font-weight-bold ms-auto">{{
              tax_include
            }}</span>
          </div>
        </v-col>
        <v-col lg="3" class="ms-auto"> </v-col>
      </v-row>
      <v-row v-if="order_info.status == shop_status_enum.Paying">
        <v-col class="ms-auto"> </v-col>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="
            font-weight-bold
            text-xs
            btn-default
            bg-gradient-default
            py-5
            px-6
            mt-6
            mb-2
            me-2
            ms-auto
          "
          color="primary"
          @click="createPay()"
        >
          {{ $t("Payment") }}
        </v-btn>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { shop_status_enum_str, shop_status_enum } from "@/definitions.js";
export default {
  name: "shop-order-detail",
  props: {
    buy_items: {
      type: Array,
      default: () => [],
    },
    order_info: {
      type: Object,
      default: () => {},
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  data(vm) {
    return {
      shop_status_enum: shop_status_enum,
      shop_status_enum_str: shop_status_enum_str,
      allow_back: false,
      order_id: null,
      loading: false,
      // order_info: {},
      // buy_items: [],
      buy_options: {},
      info_headers: [
        {
          text: vm.$i18n.t("name"),
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },

        {
          text: vm.$i18n.t("key_num"),
          value: "key_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("owner_num"),
          value: "owner_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("Price"),
          value: "price",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
      ],
      point_headers: [
        {
          text: vm.$i18n.t("name"),
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("price"),
          value: "price",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("point"),
          value: "point",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("bonus"),
          value: "bonus",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    total() {
      // return this.editedIndex === -1 ? "New Item" : "Edit Item";

      let total = 0;
      this.buy_items.forEach((item) => {
        total += parseInt(item.price);
      });
      return total;
    },
    tax() {
      return Math.round(0.05 * this.total);
    },
    tax_include() {
      return this.total + this.tax;
    },
    headers() {
      if (this.order_info != null && this.order_info.shop_type == 1) {
        return this.point_headers;
      }
      return this.info_headers;
    },
  },
  mounted() {
    if (this.$route.query.order_id) {
      this.order_id = this.$route.query.order_id;
    }
    if (this.$route.query.back == 1) {
      console.log("allow back");
      this.allow_back = true;
    }
  },
  methods: {
    ...mapGetters(["getToken"]),
    getOrderTitle() {
      if (this.order_info.status == shop_status_enum.Paying) {
        return "等待支付";
      }
      return "訂單完成";
    },
    getOrderData() {
      if (this.order_id == null) {
        this.loading = false;
        console.log("no found,show not found");
        return;
      }
      const token = this.getToken();
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/shops/${this.order_id}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      const currentObj = this;
      currentObj.loading = true;
      currentObj.result_display = true;
      this.axios
        .get(url, config)
        .then((response) => {
          currentObj.setResult(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setResult(data) {
      console.log(data);
      this.buy_items = data.items;
      this.order_info = data;
    },
    createPay() {
      const order_id = this.order_info.order_id;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/ccard/?order_id=${order_id}`;
      window.location.href = url;
    },
  },
};
</script>
