<template>
  <v-card class="card-shadow">
    <!-- TODO kent 了解為啥  會有scroll bar-->
    <v-dialog
      v-model="show_create_dialog"
      content-class="position-absolute top-0"
      width="90%"
      @keydown.esc="closeDialog()"
      @click:outside="closeDialog()"
    >
      <calendar-detail
        v-if="edit_event"
        :schedule_id="edit_schedule_id"
      ></calendar-detail>
      <calendar-buy
        ref="buy_dialog"
        :start_date="start_date"
        v-on:onClose="onClose"
        v-else
      ></calendar-buy>
    </v-dialog>
    <div class="px-4 py-4">
      <full-calendar
        :options="calendarOptions"
        ref="fullCalendar"
      ></full-calendar>
    </div>
  </v-card>
</template>

<script>
import {
  shop_type_enum,
  shop_status_enum_info,
  schedule_status_enum_str,
} from "@/definitions.js";
import { mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarBuy from "./CalendarBuy.vue";
import CalendarDetail from "./CalendarDetail.vue";
import CommonUtility from "@/util/CommonUtility.js";
import zh_tw from "@fullcalendar/core/locales/zh-tw";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";

export default {
  components: {
    FullCalendar,
    CalendarDetail,
    CalendarBuy,
  },
  mixins: [HttpCommonMixin],
  data() {
    return {
      shop_type_enum: shop_type_enum,
      shop_status_enum_info: shop_status_enum_info,
      schedule_status_enum_str: schedule_status_enum_str,
      show_create_dialog: false,
      edit_schedule_id: null,
      edit_event: false,
      start_date: null,
      weekends: null,
      events: [],
      holidays: [],
      full_days: [],
      last_get_no_service: null,
      calendarOptions: {
        locale: zh_tw,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        contentHeight: "auto",
        initialView: "dayGridMonth",
        selectable: true,
        initialDate: "2023-01-08",
        editable: false,
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "today prev,next", // will normally be on the right. if RTL, will be on the left
        },
        dateClick: (info) => {
          this.showDialog(false, info.dateStr);
        },
        eventClick: (info) => {
          this.edit_schedule_id = parseInt(info.event.id);
          console.log("event id ", this.edit_schedule_id);
          this.showDialog(true, info.dateStr);
        },
        datesSet: (info) => {
          const start = info.start;
          const end = info.end;
          this.weekends = CommonUtility.getWeekendDays(start, end);
          this.getNoService();
        },
        dayCellDidMount: (info) => {
          const date = info.date;
          const day = date.getDay();
          if (day === 0 || day === 6) {
            // find the child of info.el with class "fc-daygrid-day-top"
            this.setDayHeader(info.el, "red");
          }
        },
        events: [],
      },
    };
  },

  beforeMount() {
    const now = CommonUtility.getNowDate();
    this.calendarOptions.initialDate = now;
    this.getSchedules();
  },
  methods: {
    ...mapGetters(["getToken"]),
    isWeekend(start_date) {
      const date = new Date(start_date);
      const day = date.getDay();
      if (day === 0 || day === 6) {
        return true;
      }
      return false;
    },
    showDialog(edit, start_date) {
      const now = CommonUtility.getNowDate();
      // check if the start_date in this.full_days
      const isWeekend = this.isWeekend(start_date);
      if (isWeekend == true) {
        alert(`系統休息日 無法創建訂單`);
        return;
      }
      if (this.full_days.includes(start_date)) {
        alert(`系統滿載 無法從${start_date}開始.`);
        return;
      }

      if (start_date < now) {
        alert("無法創建過去的訂單");
        return;
      }
      this.show_create_dialog = true;
      this.edit_event = edit;
      this.start_date = start_date;
    },
    closeDialog() {
      if (this.$refs.buy_dialog) {
        this.$refs.buy_dialog.reset();
      }
      this.show_create_dialog = false;
      this.start_date = null;
    },
    onClose() {
      console.log("on Close event ");
      this.closeDialog();
    },
    onGetScheduleDone() {
      this.loading = false;
      this.result_display = true;
    },
    getSchedules() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/schedules/now`;
      const params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      const currentObj = this;
      currentObj.loading = true;
      currentObj.result_display = true;

      this.doHttpGet(url, params, this.setResult, null, this.onGetScheduleDone);
    },
    
    setResult(data) {
      this.calendarOptions.events = this.covertDataToEvents(data);
      this.events = data;
    },
    getColor(status) {
      //  iterator the schedule_status_enum_str , if the status is equal the value of item , return the class
      for (
        let index = 0;
        index < this.schedule_status_enum_str.length;
        index++
      ) {
        const element = this.schedule_status_enum_str[index];
        if (element.value == status) {
          return element.class;
        }
      }

      return "bg-gradient-light";
    },
    filterEvent(value) {
      // get the status of item is equal value from this.events list
      const events = this.covertDataToEvents(this.events, value);
      this.calendarOptions.events = events;
    },
    covertDataToEvents(data, value = null) {
      let events = [];
      data.forEach((element) => {
        if (value == null || element.status == value) {
          const event_class = this.getColor(element.status);
          let event = {
            title: element.description,
            start: element.start_date,
            end: element.end_date,
            className: event_class,
            id: element.id,
          };
          events.push(event);
        }
      });
      return events;
    },
    // get no-service
    getNoService() {
      const now = new Date();
      if (this.last_get_no_service == null) {
        this.last_get_no_service = now;
      } else {
        const delta = now - this.last_get_no_service;
        if (delta < 100) {
          this.last_get_no_service = now;
          return;
        }
        this.last_get_no_service = now;
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/systems/no-service`;
      const params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      this.doHttpGet(url, params, this.covertNoService, null, null);
    },
    setDayHeader(ele, backgroundColor) {
      ele.querySelectorAll(".fc-daygrid-day-top").forEach((item) => {
        item.style.backgroundColor = backgroundColor;
        item.querySelectorAll(".fc-daygrid-day-number").forEach((item) => {
          item.style.color = "white";
        });
      });
    },
    covertNoService(data) {
      this.full_days = data.full;
      this.full_days.forEach((element) => {
        if (this.isWeekend(element)) {
          return;
        }
        const target = `[data-date="${element}"]`;
        let ele = document.querySelector(target);
        if (ele != null) {
          this.setDayHeader(ele, "orange");
        }
      });
      // holiday
      let holidays = [];
      data.holidays.forEach((element) => {
        holidays.push(element);
        if (this.isWeekend(element)) {
          return;
        }
        const target = `[data-date="${element}"]`;
        let ele = document.querySelector(target);
        if (ele != null) {
          this.setDayHeader(ele, "red");
        }
      });
      this.holidays = holidays;
    },
  },
};
</script>

<!-- NOTE -->
<!-- assets/scss/soft-ui-dashboard/_variables.scss: 可以調整UI 風格 -->
