<template>
  <v-toolbar flat>
    <v-row class="justify-between">
      <v-col>
        <v-icon dense>mdi-calendar-arrow-right</v-icon>
        開始: {{ start_date }}
      </v-col>
      <v-col>
        <v-icon dense>mdi-calendar-arrow-left</v-icon>
        結束: {{ end_date }}
      </v-col>
      <v-col>
        <v-icon dense>mdi-store-clock-outline</v-icon>
        預計: {{ estimate_date }} 天
      </v-col>
      <v-col>
        <v-icon dense>mdi-currency-usd</v-icon>
        金額：{{ total }} (未稅)
      </v-col>
      <v-col>
        <v-icon dense class="px-2" color="orange"
          >mdi-alpha-p-circle-outline</v-icon
        >點數:{{ point_amount }}
      </v-col>
      <v-col>
        <v-icon dense class="px-2" color="red"
          >mdi-alpha-r-circle-outline</v-icon
        >回饋:{{ bonus_amount }}
      </v-col>
    </v-row>
  </v-toolbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    buy_items: {
      type: [Array],
      default: () => [],
    },
    start_date: {
      type: String,
      default: null,
    },
    end_date: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      point_amount: 0,
      bonus_amount: 0,
    };
  },
  mounted() {
    this.getPoints();
  },
  computed: {
    estimate_date() {
      let est_date = 0;
      // get the time delta between end_date and start_date
      if (this.end_date != null) {
        const start_date = new Date(this.start_date);
        const end_date = new Date(this.end_date);
        const time_diff = end_date.getTime() - start_date.getTime();
        est_date = Math.round(time_diff / (1000 * 60 * 60 * 24));
      }

      return est_date;
    },
    total() {
      let total = 0;
      this.buy_items.forEach((item) => {
        total += parseInt(item.price);
      });
      return total;
    },
  },
  methods: {
    ...mapGetters(["getCapability", "getToken"]),
    getPoints() {
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/points`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setResult(data) {
      console.log(data);
      this.point_amount = data.point_amount;
      this.bonus_amount = data.bonus_amount;
    },
  },
};
</script>
