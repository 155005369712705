<template>
  <v-card class="my-auto px-4 py-4">
    <v-row>
      <v-dialog v-model="upload_dialog" persistent width="50%">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ $t("Upload File") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label class="text-xs text-typo font-weight-bolder ms-1"
                    >Keys File
                  </label>
                  <v-file-input
                    v-model="keys_file"
                    accept=".xlsx,.xls,.csv"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="upload_dialog = false"
            >
              Close
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" @click="onUploadSave()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog></v-row
    >
    <v-row>
      <v-col cols="12">
        <v-card v-if="step == buy_steps_enum.Init">
          <v-row class="mt-0">
            <v-col class="text-right">
              <v-btn icon rounded class="ms-6" @click="$emit('onClose')">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-col
            >
          </v-row>
          <v-row class="mt-0">
            <v-col sm="2" cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1">{{
                $t("city")
              }}</label>
              <v-select
                :items="city_info"
                v-model="select_city"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="38"
                @change="onCitySelect()"
              ></v-select>
            </v-col>
            <v-col sm="2" cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1">{{
                $t("district")
              }}</label>
              <v-select
                :items="district_info"
                v-model="select_district"
                item-text="district_name"
                item-value="district_code"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="38"
                @change="get_regions()"
              >
              </v-select>
            </v-col>
            <v-col sm="4" cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1">{{
                $t("region")
              }}</label>
              <v-select
                :items="region_info"
                v-model="select_region"
                item-text="region_name"
                item-value="region_code"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="38"
              >
              </v-select>
            </v-col>
            <v-col sm="2" cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1">{{
                $t("types")
              }}</label>
              <v-select
                :items="types"
                value="私設"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="38"
              >
              </v-select>
            </v-col>
            <v-col>
              <div class="d-flex justify-space-around">
                <v-btn
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    px-6
                    py-2
                    mt-8 mt-2
                  "
                  color="primary"
                  @click="search"
                >
                  {{ $t("Search") }}
                </v-btn>
                <v-btn
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-secondary
                    px-6
                    py-2
                    mt-2 mt-8
                  "
                  color="primary"
                  @click="upload_dialog = true"
                >
                  {{ $t("Upload") }}
                </v-btn>
                <v-btn
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-primary
                    px-6
                    py-2
                    mt-2 mt-8
                  "
                  color="primary"
                  @click="reset"
                >
                  {{ $t("Reset") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <!-- 預估費用 顯示地號 人數 費用 -->
          <v-row>
            <v-col cols="12" mx-0 pa-0>
              <v-data-table
                :loading="loading"
                v-show="result_display"
                :headers="headers"
                :options.sync="result_options"
                :items="result_items"
                :server-items-length="result_count"
                hide-default-footer
                disable-pagination
                v-model="buy_items"
                @click:row="handleClick"
              >
                <template v-slot:top>
                  <calendar-buy-header
                    :buy_items="buy_items"
                    :start_date="start_date"
                    :end_date="end_date"
                  ></calendar-buy-header>
                </template>
                <template v-slot:item.point="{ item }">
                  {{ getPointPrice(item.price) }}
                </template>
                <template v-slot:loading>
                  {{ $t("Loading...") }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div v-show="result_display">
            <v-row>
              <v-col cols="11">
                <v-pagination
                  prev-icon="fa fa-angle-left"
                  next-icon="fa fa-angle-right"
                  class="pagination"
                  color="#cb0c9f"
                  v-model="result_page"
                  :length="getPageCount()"
                  circle
                  :total-visible="15"
                ></v-pagination>
              </v-col>
              <v-col cols="1">
                <v-btn
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mt-6
                    mb-2
                    me-2
                  "
                  color="primary"
                  @click="evaluate"
                >
                  {{ $t("Evaluate") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card v-if="step == buy_steps_enum.Summary">
          <CalendarOrderSummary
            :buy_items="buy_items"
            :start_date="start_date"
            :end_date="end_date"
            v-on:paymentChange="onPaymentChange"
            ref="order_summary"
          >
            <template v-slot:header>
              <calendar-buy-header
                :buy_items="buy_items"
                :start_date="start_date"
                :end_date="end_date"
                ref="order_header"
              ></calendar-buy-header>
            </template>
            <template v-slot:summary>
              <v-data-table
                :headers="summary_headers"
                :options.sync="buy_options"
                :items="buy_items"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:item.point="{ item }">
                  {{ getPointPrice(item.price) }}
                </template>
              </v-data-table>
            </template>
          </CalendarOrderSummary>
          <div class="d-flex">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs text-dark
                btn-light
                bg-gradient-light
                py-5
                px-6
                mt-6
                mb-2
                ms-2
              "
              @click="step = buy_steps_enum.Init"
            >
              {{ $t("Prev") }}
            </v-btn>
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-default
                py-5
                px-6
                mt-6
                mb-2
                me-2
                ms-auto
              "
              color="primary"
              @click="createShopList()"
            >
              {{ $t("Payment") }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import { fake_search_result } from "@/test_data.js";
import { shop_type_enum, payment_enum, buy_steps_enum } from "@/definitions.js";
import CDRBase from "@/views/Pages/General/Widgets/CDRBase.vue";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import CalendarOrderSummary from "./CalendarOrderSummary.vue";
import CalendarBuyHeader from "./CalendarBuyHeader.vue";
export default {
  components: {
    CalendarOrderSummary,
    CalendarBuyHeader,
  },
  props: {
    start_date: {
      type: String,
      default: null,
    },
  },
  mixins: [CDRBase, HttpCommonMixin],
  data(vm) {
    return {
      end_date: null,
      buy_steps_enum: buy_steps_enum,
      upload_dialog: false,
      keys_file: null,
      tmp_file: null,
      step: buy_steps_enum.Init,
      loading: false,
      types: ["私設", "造冊"],
      payment_enum: payment_enum,
      pay: payment_enum.CreditorCard,
      shop_type_enum: shop_type_enum,
      result_display: false,
      result_page: 1,
      result_items_per_page: 10,
      result_options: {},
      result_items: [],
      result_count: 0,
      buy_items: [],
      // fake data
      buy_options: {},
      headers: [
        {
          text: vm.$i18n.t("district_name"),
          value: "district_name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("region_name"),
          value: "region_name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("key_num"),
          value: "key_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("owner_num"),
          value: "owner_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("Price"),
          value: "price",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("Point Price"),
          value: "point",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        // {
        //   text: vm.$i18n.t("stock_price"),
        //   value: "stock_price",
        //   class: "text-secondary font-weight-bolder opacity-7",
        //   sortable: false,
        // },
      ],
      fake_items_count: 10,
      fake_items: fake_search_result,
      confirm_point: false,
      summary_headers: [
        {
          text: vm.$i18n.t("district_name"),
          value: "district_name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("region_name"),
          value: "region_name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("key_num"),
          value: "key_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("owner_num"),
          value: "owner_num",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("Price"),
          value: "price",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("Point Price"),
          value: "point",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
      ],
      develop_pay: process.env.NODE_ENV === "development" ? true : false,
    };
  },
  mounted() {
    console.log("this ", this.select_city, " district:", this.select_district);
    this.get_districts();
  },
  watch: {
    result_page() {
      if (this.result_display == true) {
        this.search();
      }
    },
  },
  computed: {},
  methods: {
    ...mapMutations(["reset_cache"]),
    getPageCount() {
      return Math.ceil(this.result_count / this.result_items_per_page);
    },
    reset() {
      if (this.$refs.order_summary) this.$refs.order_summary.reset();

      this.result_items = 0;
      this.result_items = [];
      this.result_page = 1;
      this.result_display = false;
      this.buy_items = [];
      this.select_city = "H";
      this.select_district = "01";
      const ALL_ID_STR = "9999";
      this.select_region = ALL_ID_STR;
      this.step = buy_steps_enum.Init;
      // this.reset_cache();
      console.log("Reset the dialog.");
    },
    search() {
      const fake = false;
      if (fake) {
        this.result_display = true;
        this.result_items = this.fake_items;
        this.result_count = this.fake_items_count;
        return;
      }

      if (this.select_city == null || this.select_district == null) {
        alert("NO select");
        return;
      }

      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/products/districts`;
      let params = {
        city: this.select_city,
        district: this.select_district,
        page: this.result_page,
      };

      if (this.select_region != null && this.select_region != "9999") {
        params["regions"] = this.select_region;
      }

      this.loading = true;
      this.result_display = true;
      this.doHttpGet(url, params, this.onSearchResult, null, this.onSearchDone);
    },
    onSearchResult(data) {
      this.result_items = data.results;
      this.result_count = data.count;
    },
    onSearchDone() {
      this.loading = false;
    },
    handleClick(item) {
      // if (this.buy_items.includes(item)) {
      // if (buy_items.some(item => item.id === item_id)) {
      const target = item.id;
      const index = this.buy_items.findIndex((item) => item.id === target);
      // const index = this.buy_items.indexOf(item);
      if (index > -1) {
        // only splice array when item is found
        this.buy_items.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        console.log(item);
        this.buy_items.push(item);
      }
    },
    evaluate() {
      console.log(
        "Here we call the server to get the item and price and display"
      );
      if (this.buy_items.length == 0) {
        alert("No select");
        return;
      }
      const url = process.env.VUE_APP_SERVER_URL + "/api/v1/shops/evaluate";
      let total_key = 0;
      this.buy_items.forEach((item) => {
        total_key += item.key_num;
      });
      let data = {
        total: total_key,
        start_date: this.start_date,
      };
      this.doHttpPost(url, data, this.onEvaluateResult);
    },
    onEvaluateResult(data) {
      console.log(data);
      this.end_date = data.end_date;
      this.step = buy_steps_enum.Summary;
    },
    // displaySummary & payShopListSuccess 是來測試用的
    displaySummary(data) {
      console.log(this.$refs.order_summary.payment);
      if (this.$refs.order_summary.payment == 1) {
        alert("訂單更新完成 點數付帳");
      } else {
        alert("訂單更新完成 信用卡付帳");
      }
      const order_id = data.order_id;
      this.payShopListSuccess(order_id);
      console.log(data);
      console.log("order id ", order_id);
      this.$router.push({
        path: "/pages/customer/order-done/",
        query: { order_id },
      });
    },
    payShopListSuccess(order_id) {
      console.log("pay shopping list success", order_id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/shops/${order_id}`;
      this.doHttpPut(url, { order_id: order_id });
    },
    createPay(order_id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/ccard/?order_id=${order_id}`;
      window.location.href = url;
    },
    createShopList() {
      const url = process.env.VUE_APP_SERVER_URL + "/api/v1/shops/";
      let items = [];
      let name = null;

      this.buy_items.forEach((item) => {
        if (name == null) {
          name = `${item.city_name}${item.district_name} ${item.region_name}`;
        }
        items.push({
          price: item.price,
          point: this.getPointPrice(item.price),
          stock: item.stock_price,
          quantity: 1,
          name: `${item.city_name}${item.district_name} ${item.region_name}`,
          cdr: `${this.select_city}_${this.select_district}_${item.region}`,
          key_num: item.key_num,
          owner_num: item.owner_num,
          creditor_num: 0,
        });
      });
      name += ` 等${this.buy_items.length}段`;

      let data = {
        total: this.$refs.order_summary.total,
        tax: this.$refs.order_summary.tax,
        tax_include: this.$refs.order_summary.tax_include,
        name: name,
        start_date: this.start_date,
        end_date: this.$refs.order_header.end_date,
        items: items,
        shop_type: shop_type_enum.Information,
        filename: this.tmp_file,
      };
      // TODO credit , point, bonus...
      if (this.pay == this.payment_enum.CreditorCard) {
        data["credit"] = this.$refs.order_summary.tax_include;
      } else if (this.pay == this.payment_enum.Point) {
        data["point"] = this.$refs.order_summary.tax_include;
      }

      console.log("createShopList data", data);
      this.doHttpPost(url, data, this.onShopListCreated);
    },
    onShopListCreated(data) {
      if (this.develop_pay == true) {
        this.displaySummary(data);
      } else {
        this.createPay(data.order_id);
      }
    },
    onPaymentChange(val) {
      this.pay = val;
      // console.log("on Payment change", this.pay);
    },
    getPointPrice(price) {
      return Math.round(parseInt(price) * 0.95);
    },
    showUploadDialog() {
      this.upload_dialog = true;
    },
    onUploadSave() {
      this.dialog = false;
      const currentObj = this;
      const token = this.getToken();

      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/shops/file`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      let formData = new FormData();
      formData.append("file", this.keys_file);

      this.axios
        .post(url, formData, config)
        .then(function (response) {
          console.log(response.data);
          // alert("新增成功");
          currentObj.setUploadResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.upload_dialog = false;
        });
    },
    setUploadResult(data) {
      const item = data.result;
      this.buy_items.push(item);
      this.tmp_file = item.filename;
      this.step = buy_steps_enum.Summary;

      // let item = {
      //   id: "8108",
      //   city: "City object (8)",
      //   city_name: "桃園市",
      //   district: "District object (165)",
      //   district_name: "桃園區",
      //   region: "0015",
      //   region_name: "和平段",
      //   key_num: 1405,
      //   owner_num: 8515,
      //   type: 0,
      //   price: 3406,
      //   stock_price: 851,
      // };
    },
  },
};
</script>

<style>
tr .v-data-table__selected {
  background: #7d92f5 !important;
}
</style>
