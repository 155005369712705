<template>
  <shop-order-detail
    :buy_items="scheduleInfo.shop.items"
    :order_info="scheduleInfo.shop"
  ></shop-order-detail>
</template>

<script>
import ShopOrderDetail from "./ShopOrderDetail.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    calendar_dialog: {
      type: Boolean,
      default: false,
    },
    schedule_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    ShopOrderDetail,
  },
  data() {
    return {
      scheduleInfo: { shop: {} },
    };
  },
  watch: {
    schedule_id(val) {
      console.log("schedule_id--->", val);
      this.getScheduleByID(val);
    },
  },
  mounted() {
    console.log("try to get the schedule detail", this.schedule_id);
    if (this.schedule_id != null) {
      this.getScheduleByID(this.schedule_id);
    }
  },
  methods: {
    ...mapGetters(["getToken"]),
    getScheduleByID(schedule_id) {
      console.log("getScheduleByID", schedule_id);
      const token = this.getToken();
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/schedules/${schedule_id}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        params: {
          page: this.page,
          size: this.itemsPerPage,
        },
      };

      const currentObj = this;
      currentObj.loading = true;
      currentObj.result_display = true;
      this.axios
        .get(url, config)
        .then((response) => {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setResult(data) {
      this.scheduleInfo = data;
      console.log(data);
    },
  },
};
</script>
